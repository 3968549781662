<template>
  <div class="orderHistory">
    <mt-header :title="$t('orderHistory')">
      <router-link to slot="left">
        <mt-button @click="$router.back(-1)">
          <img src="@/assets/back1.png" height="20" width="20" slot="icon">
          {{ $t('back') }}
        </mt-button>
      </router-link>
      <call-phone slot="right" />
    </mt-header>
    <div class="content">
      <ul>
        <li v-for="(item, index) in contractList" :key="index">
          <div class="box">
            <div class="box-title">
              <img :src="item.iconUrl" alt=""/>
              <span>{{ item.productName }}</span>
              <span class="box-left">{{ item.applyCount }} Préstamos</span>
            </div>
            <ul class="item-ul" >
              <li class="item-li" v-for="(contract, key) in item.contractDetails" :key="key" @click="$router.push({ path: '/apply/orderDetails', query: { contractNo: contract.applyId } })">
                <div class="wrap">
                  <div class="row border-b">
                    <div class="row-text">{{ contract.applyAmount | formatMoney }}</div>
                    <div class="row-text row-gap">{{ contract.applyDate}}</div>
                  </div>
                </div>
                <span class="item-status">{{ contract.contractStatusName}}</span>
                <img class="item-arrow" src="../../assets/arrow-r.png" alt="">
              </li>
            </ul>
          </div>
          <div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import callPhone from '@/components/callPhone.vue'
import { contractList } from '../../utils/api'
export default {
  name: 'OrderHistory',
  components: {
    callPhone
  },
  data() {
    return {
      loading: false,
      list: [],
      contractList: [],
    }
  },
  computed: {
    ...mapState(["user"]),
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      this.$indicator.open({
        spinnerType: "fading-circle",
      });
      this.$NProgress.start()
      await this.loadMore()
      this.$indicator.close();
      this.$NProgress.done()
    },
    async loadMore() {
      this.loading = true;
      await this.$axios({
        method: 'post',
        url: contractList,
      })
        .then((e) => {
          if (e.status.code === '000') {
            this.contractList = e.body;
            this.loading = false;
          }
        })
        .catch(() => {})
    }
  }
}
</script>

<style lang="scss" scoped>
.orderHistory {
  background: url("../../assets/header-bg.png") no-repeat;
  background-size: 100% auto;
  min-height: 500px;
  .content{
    margin: 10px 35px;
    padding-top: 50px;
    .box{
      background: linear-gradient(to bottom, #F9F6FF, #E2D6FF);
      border-radius: 20px;
      margin-top: 10px;
    }
    .box-title{
      display: flex;
      align-items: center;
      font-weight: bold;
      height: 50px;
      line-height: 50px;
      img {
        width: 30px;
        margin: 0 15px;
        border-radius: 50%;
      }
      .box-left{
        margin: auto;
        font-size: 12px;
        color: #855EF4;
      }
    }
  }

  ul,li {
    padding: 0;
    margin: 0;
    list-style: none;
  }
  .item-ul {
    flex-grow: 1;
    position: relative;
    .item-li {
      padding: 10px 20px 20px 20px;
      text-decoration: none;
      border-bottom: 2px solid #FFFFFF;
      display: flex;
      align-items: center;
    }
    .item-status{
      font-size: 14px;
      color: #855EF4;
    }
    .item-arrow{
      margin-left: 3px;
      height: 13px;
    }
    .row-text{
      color: #666666;
    }
    .row-gap{
      margin-top: 5px;
    }
    .wrap {
      flex: 1;
      border-radius: 10px;
    }
    .title {
      display: flex;
      align-items: center;
      height: 45px;
      color: #111111;
      font-size: 18px;
      img {
        width: 20px;
        margin: 0 15px;
      }
      div {
        color: #000;
        padding-left: 20px;
      }
    }
    .row {
      height: 30px;
      color: #A8A8A8;
      font-size: 14px;
      font-weight: bold;
      align-items: center;
    }
  }
}
</style>